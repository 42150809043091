import "../assets/styles/home.scss";
import { Row, Col, Image, Stack, Button } from "react-bootstrap";
import profilePhoto from "../assets/images/profilePhoto.png";

export function Home (){
    return(
        <div id="home">
            <main>
                <Row>
                    <Col lg={{offset: 3, span: 8}} sm={12} none={12} >
                        <Image src={profilePhoto} style={{width: "20rem"}} thumbnail id="profilePhoto"  />
                    </Col>
                </Row>
                <Row>
                    <Col lg={{offset: 3, span: 4}}>
                        <h3>Thiago Vieira</h3>
                        <h4>Engenheiro de software</h4>
                        <h4>UniCesumar-PR</h4>
                    </Col>
                </Row>
                <Row id="menuSmart">
                    <Col>
                        <Stack gap={3} id="stackMenu">
                            <Button href="/#/presentation" variant="success">Apresentação</Button>
                            <Button href="/#/projects" variant="info">Projetos</Button>
                            <Button href="/#/contacts">Contato</Button>
                        </Stack>
                    </Col>
                </Row>
            </main>
        </div>
    )
}